import {Injectable} from '@angular/core';
import {DefaultService} from '@core/services/default.service';

@Injectable({
  providedIn: 'root'
})
export class RegionService extends DefaultService {

  formName: 'call-center/regions';


  getUrl(): string {
    return 'call-center/api/v1/regions';
  }
}
